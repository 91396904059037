<template>
  <div>
    <PageTitle
      :items="{
        title: title,
        subTitle: subTitle,
        buttons: [
          {
            title: '詳細条件検索',
            class: 'bg-white',
            others: {
              outlined: true
            },
            action: () => {
              toggleSearch = !toggleSearch
            }
          },
          {
            title: '新規作成',
            icon: 'mdi-plus-circle',
            action: () => {
              $router.push({ name: 'InternshipPostCreate' })
            }
          }
        ],
        count: getInternshipCounts ? getInternshipCounts.total_draft : 0
      }"
    ></PageTitle>
    <SearchArea
      v-if="toggleSearch"
      @toggleSearch="setToggleSearch($event)"
      @searchSubmit="searchSubmit($event)"
      @changedInputType="setChangedInputType($event)"
      @resetForm="getDataFromApi()"
      :toggleSearch="toggleSearch"
      :searchFields="searchFields"
      :selectTypeOptions="selectTypeOptions"
      class="mb-4"
    ></SearchArea>
    <DataTable
      :items="getAllInternship"
      :headers="headers"
      :total-records="totalRecords ? totalRecords : 0"
      :number-of-pages="totalPages ? totalPages : 0"
      ref="pagination"
      @click:row="
        $router.push({
          name: 'InternshipPostEdit',
          params: { id: $event.id }
        })
      "
      @update:options="getDataFromApi"
    >
      <template v-slot:[`item.title`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              v-bind="attrs"
              v-on="on"
              class="text-truncate"
              max-width="180px"
              width="auto"
              min-width="100px"
              color="transparent"
            >
              {{ item.title }}
            </v-sheet>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.company_name`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              v-bind="attrs"
              v-on="on"
              class="text-truncate"
              max-width="180px"
              min-width="100px"
              color="transparent"
            >
              {{ item.company ? item.company.name : '-' }}
            </v-sheet>
          </template>
          <span>{{ item.company ? item.company.name : '-' }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.business_industry_name`]="{ item }">
        {{
          item.company && item.company.business_industry
            ? item.company.business_industry.name
            : '-'
        }}
      </template>
      <template v-slot:[`item.work_category_name`]="{ item }">
        {{ item.work_category ? item.work_category.name : '-' }}
      </template>
      <template v-slot:[`item.public_date`]="{ item }">
        {{
          item.public_date && item.public_date != ''
            ? item.public_date
            : '-' | moment('YYYY/MM/DD')
        }}
      </template>
    </DataTable>
  </div>
</template>

<script>
import SearchArea from '@/components/ui/SearchArea'
import PageTitle from '@/components/ui/PageTitle'
import DataTable from '@/components/ui/DataTable'
import { mapGetters } from 'vuex'

export default {
  name: 'InternshipPostDraftList',
  components: {
    DataTable,
    PageTitle,
    SearchArea
  },

  data() {
    return {
      title: '求人広告',
      subTitle: '下書き',
      toggleSearch: false,
      initialLoad: true,
      searchFields: [],
      selectTypeOptions: [
        { id: 'search', name: 'キーワード検索' },
        { id: 'industry', name: '業界' },
        { id: 'work', name: '職種' },
        { id: 'public_date', name: '公開日' }
      ],

      headers: [
        {
          text: 'No.',
          align: 'center',
          sortable: false,
          width: '10.69%',
          value: 'id'
        },
        {
          text: '求人タイトル',
          align: 'left',
          sortable: false,
          width: '20.27%',
          value: 'title'
        },
        {
          text: '企業名',
          align: 'left',
          sortable: false,
          width: '20.27%',
          value: 'company_name'
        },
        {
          text: '業界',
          align: 'left',
          sortable: false,
          width: '20.27%',
          value: 'business_industry_name'
        },
        {
          text: '職種',
          align: 'left',
          sortable: false,
          width: '14.58%',
          value: 'work_category_name'
        },
        {
          text: '公開日',
          align: 'left',
          sortable: true,
          width: '10%',
          value: 'public_date'
        },
        {
          text: '',
          align: 'center',
          value: 'favorites_count',
          heartIcon: true,
          sortable: true
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'applications_count',
          paperPlaneIcon: true,
          sortable: true,
          width: '5%'
        }
      ],
      userSearchedInput: {}
    }
  },
  created() {
    this.getDataFromApi()
  },
  computed: {
    ...mapGetters([
      'getAllInternship',
      'getInternshipPagination',
      'getInternshipCounts'
    ]),
    totalRecords() {
      return this.getInternshipPagination?.records_total || 0
    },
    totalPages() {
      return this.getInternshipPagination?.total_pages || 0
    }
  },
  methods: {
    resetPagination() {
      this.$refs.pagination.currentPage = 1
      ++this.$refs.pagination.updatePaginate
    },
    setChangedInputType(inputSearchType) {
      if (inputSearchType == 'search') {
        this.searchFields = [
          {
            label: 'Label',
            name: 'search',
            type: 'text',
            value: '',
            placeholder: '求人ID、求人タイトル、企業名'
          }
        ]
      } else if (inputSearchType == 'public_date') {
        this.searchFields = [
          {
            label: 'Label',
            name: 'date_from',
            type: 'date',
            rules: 'required',
            show_after_approx: true,
            value: `${this.$moment().format('YYYY-MM-DD')}`,
            menu: false,
            locale: 'ja',
            date_format: 'YYYY-MM-DD 00:00'
          },
          {
            label: 'Label',
            name: 'date_to',
            type: 'date',
            rules: 'required',
            show_after_approx: false,
            value: `${this.$moment().format('YYYY-MM-DD')}`,
            menu: false,
            locale: 'ja',
            range: true,
            range_input: 'date_from',
            date_format: 'YYYY-MM-DD 23:59'
          }
        ]
      } else if (inputSearchType == 'industry') {
        this.searchFields = [
          {
            label: 'Label',
            name: 'industry_id',
            type: 'select',
            rules: 'required',
            item_value: 'id',
            item_text: 'name',
            items: this.getMasterData?.business_industories
          }
        ]
      } else if (inputSearchType == 'work') {
        this.searchFields = [
          {
            label: 'Label',
            name: 'work_id',
            type: 'select',
            rules: 'required',
            item_value: 'id',
            item_text: 'name',
            items: this.getMasterData?.work_categories
          }
        ]
      } else {
        this.searchFields = {}
      }
    },
    setToggleSearch($event) {
      this.resetPagination()
      this.userSearchedInput = {}
      this.toggleSearch = $event
      this.getDataFromApi()
    },
    searchSubmit($event) {
      this.userSearchedInput = {}
      this.resetPagination()
      if ($event.fields.length > 0) {
        $event.fields.forEach(field => {
          this.userSearchedInput[field.name] = field.value
        })

        this.getDataFromApi(undefined)
      }
    },
    async getDataFromApi(e = undefined) {
      this.loading = true

      let data = {}
      data.sort_by_order = e?.sortDesc[0] ? 'asc' : 'desc'
      data.sort_by = e?.sortBy[0] ? e.sortBy[0] : 'updated_at'
      data.page = e?.page ? e.page : 1
      data.paginate = e?.itemsPerPage ? e.itemsPerPage : 25
      data.draft_or_public = 'draft'
      data = Object.assign({}, this.userSearchedInput, data)

      await this.$store
        .dispatch('INTERNSHIP_GET_ALL', data)
        .then(res => {
          if (this.initialLoad) {
            this.initialLoad = false
          }
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="scss" scoped></style>
